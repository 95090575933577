import React from "react";
import { graphql } from "gatsby";
import { PageWrapper } from "site-ui/src/components/PageWrapper";
import { HeroLanding } from "site-ui/src/components/HeroLanding";
import { LocaleLink as Link } from "gatsby-theme-twill-website";
import { Breadcrumbs } from "site-ui/src/components/Breadcrumbs";
import { GatsbyImage } from "gatsby-plugin-image";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ButtonLink } from "site-ui/src/components/Button";
import { Grid, Container } from "@material-ui/core";
import { Section } from "site-ui/src/components/Section";
import { ChecklistCheck } from "site-ui/src/components/ChecklistCheck";
import { Blocks } from "page-constructor/src/components/Blocks";
import { midBlue } from "site-ui/colors";
import { BLOCKS } from "@contentful/rich-text-types";
import { useTranslation } from "twill-net-localization";
import {
  options,
  renderRichText,
  renderPlainText,
} from "twill-contentful-text-render";
import { dropdownRichText } from "../../ui/components/DropdownRichText";

export default ({ pageContext, data }) => {
  const {
    contentfulService: {
      title,
      headline,
      shortDescription,
      heroImage,
      mainFeatures,
      cta,
      pageAssembly: { blocks },
    },
  } = data;
  const { t } = useTranslation();
  return (
    <PageWrapper variant="empty">
      <Breadcrumbs
        items={[
          <Link to="../">{t("common.services", "Services")}</Link>,
          title,
        ]}
      />
      <HeroLanding
        compact={true}
        gradientPoint={40}
        overlayGradientStartColor={midBlue}
        overlayGradientEndColor="rgba(38, 101, 147, 0.44)"
        triangleGradientEndColor="rgba(38, 101, 147, 0.44)"
        triangleGradientStartColor="rgba(80, 175, 200, 0.51)"
        bgAlign="right"
        bgImage={
          heroImage && (
            <GatsbyImage
              image={heroImage.localFile.childImageSharp.gatsbyImageData}
              style={{ height: "100%", width: "75%", display: "inline-block" }}
              imgStyle={{ objectFit: "cover", objectPosition: "right" }}
              alt=""
            />
          )
        }
      >
        <Box maxWidth={640} pb={4}>
          <Box pb={3}>
            <Typography variant="h5">{title}</Typography>
          </Box>
          <Typography variant="h1">{headline}</Typography>
          <Typography variant="subtitle1">
            {renderPlainText(shortDescription)}
          </Typography>
        </Box>

        {!cta ? (
          <ButtonLink href="/my/sign-up">
            {t("common.startBookingCTA", "Start booking")}
          </ButtonLink>
        ) : (
          <ButtonLink href={cta.url}>{cta.buttonLabel}</ButtonLink>
        )}
      </HeroLanding>
      <Section py={4}>
        <Grid container spacing={3}>
          {mainFeatures &&
            mainFeatures.map(({ title, description }, key) => (
              <Grid md={3} item key={key}>
                <ChecklistCheck as="div">
                  <Typography variant="h4">{title}</Typography>
                  <Typography>
                    {renderRichText(description, options)}
                  </Typography>
                </ChecklistCheck>
              </Grid>
            ))}
        </Grid>
      </Section>
      <Blocks
        blocks={blocks}
        textOptions={{
          ...options,
          renderNode: {
            ...options.renderNode,
            // [BLOCKS.UL_LIST]: dropdownRichText,
          },
        }}
      />
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query ServicePageQuery(
    $locale: String
    $contentful_id: String
    $language: String
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulService(
      node_locale: { eq: $locale }
      contentful_id: { eq: $contentful_id }
    ) {
      title
      headline
      shortDescription {
        raw
      }
      cta {
        url
        buttonLabel
      }
      mainFeatures {
        title
        description {
          raw
        }
      }
      heroImage {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
      pageAssembly {
        blocks {
          __typename
          ...HeroBlockFields
          ...CarouselBlockFields
          ...TextImageBlockFields
          ...FeaturesBlockFields
          ...RichTextBlockFields
          ...FeatureDisplayBlockFields
          ...CounterFields
          ...KnowledgeHubBlockFields
          ...SectionBlockFields
          ...ServicesBlockFields
          ...EmbedBlockFields
          ...LinkOffBlockFields
        }
      }
    }
  }
`;
