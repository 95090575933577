import React from "react";
import styled from "styled-components";
import { darkBlue, midBlue, textColor } from "../../../colors";
import { Chevron } from "../../icons";

export const Breadcrumbs = ({ items }) => {
  return (
    <Wrapper>
      {items.map((item, key) => (
        <Item isLastItem={key === items.length - 2}>
          <ChevronContainer first={key === 0}>
            <Chevron right={true} />
          </ChevronContainer>
          <Breadcrumb>{item}</Breadcrumb>
        </Item>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  font-size: 14px;
  color: ${darkBlue};
  min-height: 40px;
  padding: 0 16px;
  color: ${textColor};
  @media (min-width: 1024px) {
    padding: 0 32px;
  }
`;

const ChevronContainer = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
  transform: rotate(180deg);
  @media (min-width: 800px) {
    transform: rotate(0deg);
    ${(props) => (props.first ? "display: none;" : "")}
  }
`;

const Item = styled.div`
  ${(props) => (props.isLastItem ? "display: flex" : "display: none")};
  @media (min-width: 800px) {
    display: flex;
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  font-weight: 200;
  a {
    color: ${midBlue};
    border-bottom: 1px solid ${midBlue};
  }
`;
