import React from "react";

export const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <defs>
        <linearGradient id="1rpetq95sa" x1="100%" x2="0%" y1="50%" y2="50%">
          <stop offset="0%" stop-color="#50AFC8" />
          <stop offset="49.658%" stop-color="#266593" />
          <stop offset="99.919%" stop-color="#142145" />
          <stop offset="100%" stop-color="#142146" />
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-432 -433) translate(416 417) translate(16 15) translate(0 1)"
                />
                <path
                  fill="url(#1rpetq95sa)"
                  fill-rule="nonzero"
                  d="M12.42 1.973c3.865 0 7 3.134 7 7 0 3.463-2.516 6.34-5.82 6.9l-.215.033-.18 5.439-.008.372c-.01.552-.465.992-1.017.982-.553-.01-.992-.465-.983-1.017l.036-1.384c.037-1.21.099-2.996.187-5.362.02-.537.461-.963 1-.963 2.76 0 5-2.238 5-5 0-2.761-2.24-5-5-5-2.762 0-5 2.239-5 5 0 1.46.613 2.818 1.664 3.753.412.367.45 1 .082 1.412-.367.412-.999.45-1.411.082-1.477-1.313-2.336-3.214-2.336-5.247 0-3.866 3.134-7 7-7z"
                  transform="translate(-432 -433) translate(416 417) translate(16 15) translate(0 1) rotate(-46 12.42 12.336)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
